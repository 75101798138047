import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    margin: '24px 0 40px',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(30),
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    textAlign: 'center',
    width: 405,
  },
}));
