import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { pipe } from 'ramda';

import CreateCompanyForm from 'forms/ClaimCompany/CreateNewCompanyForm';
import JoinToCompanyForm from 'forms/ClaimCompany/JoinToCompanyForm';
import Loader from 'components/Loader';
import Icon from 'components/Icon';
import { isBlank } from 'utils/Utils';
import { loadCompanies, joinToCompany, createCompany, compareEmailWithCompanyLink } from './ClaimCompanyActions';
import Congratulations from './components/Congratulations';
import JoinToCompanyFormComponent from './components/JoinToCompanyForm';
import CreateCompanyFormComponent from './components/CreateCompanyForm';
import ConfirmCompany from './components/ConfirmCompany';
import Notification from './components/Notification';

import styles from './styles';

const FSM_STATES = {
  selectCompany: 'selectCompany',
  createCompany: 'createCompany',
  requestingAccess: 'requestingAccess',
  congratulation: 'congratulation',
  confirmCompany: 'confirmCompany',
};

class ClaimCompany extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    loadCompanies: PropTypes.func.isRequired,
    joinToCompany: PropTypes.func.isRequired,
    createCompany: PropTypes.func.isRequired,
    compareEmailWithCompanyLink: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.shape().isRequired,
  };

  state = {
    FSM: FSM_STATES.selectCompany,
    selectedCompany: {},
    form: CreateCompanyForm.defaultAttributes(),
  };

  handleChangeForm = field => event => {
    this.setState({ form: { ...this.state.form, [field]: event.target.value } });
  };

  handleSubmitForm = () => {
    const { form } = this.state;
    const errors = CreateCompanyForm.validate(form);

    if (!isBlank(errors)) {
      return this.setState({ errors });
    }

    this.setState({ errors: {} });
    return this.handleSubmitCreateNewCompanyForm(form);
  };

  loadCompanies = (searchTerm, page = 1) => {
    const params = { page, per_page: 20, q: { search_terms_cont: searchTerm, sorts: 'id' } };
    return this.props.loadCompanies(params).then(response => response.companies);
  };

  handleSubmitCreateNewCompanyForm = form => {
    const params = CreateCompanyForm.attributesToSubmit(form);
    return this.props
      .createCompany(params)
      .then(company => {
        this.props.joinToCompany(company.organizationId).then(() => {
          this.setState({ FSM: FSM_STATES.congratulation, selectedCompany: company });
        });
      })
      .catch(() => {
        this.setState({ FSM: FSM_STATES.createCompany });
      });
  };

  handleSubmitJoinToCompanyForm = form => {
    const params = JoinToCompanyForm.attributesToSubmit(form);
    const {
      selectedCompany: { organizationId },
    } = this.state;
    return this.props
      .compareEmailWithCompanyLink(params)
      .then(() => {
        this.props.joinToCompany(organizationId).then(() => {
          this.setState({ FSM: FSM_STATES.congratulation });
        });
      })
      .catch(() => {
        this.setState({ FSM: FSM_STATES.confirmCompany });
      });
  };

  handleConfirmCompany = () => {
    const {
      selectedCompany: { organizationId },
    } = this.state;
    return this.props.joinToCompany(organizationId).then(() => {
      this.setState({ FSM: FSM_STATES.requestingAccess });
    });
  };

  handleCantFindCompany = () => {
    this.setState({ FSM: FSM_STATES.createCompany });
  };

  handleChangeSelectedCompany = company => {
    this.setState({ selectedCompany: company });
  };

  handleGoBack = () => {
    this.setState({ FSM: FSM_STATES.selectCompany });
  };

  renderJoinToCompanyForm = () => {
    const { selectedCompany } = this.state;
    return (
      <JoinToCompanyFormComponent
        selectedCompany={selectedCompany}
        onLoadCompanies={this.loadCompanies}
        onSubmitForm={this.handleSubmitJoinToCompanyForm}
        onChangeSelectedCompany={this.handleChangeSelectedCompany}
        onCantFindCompany={this.handleCantFindCompany}
      />
    );
  };

  renderConfirmCompanyForm = () => {
    const { selectedCompany } = this.state;
    return (
      <ConfirmCompany
        selectedCompany={selectedCompany}
        onSubmitForm={this.handleConfirmCompany}
        onGoBack={this.handleGoBack}
      />
    );
  };

  renderTextNotification = () => {
    const { selectedCompany, FSM } = this.state;
    return <Notification fsm={FSM} fsmStates={FSM_STATES} selectedCompany={selectedCompany} />;
  };

  renderCreateCompanyForm = () => {
    const { errors: propsErrors } = this.props;
    const { errors: stateErrors, form } = this.state;
    const errors = { ...stateErrors, ...propsErrors };

    return (
      <CreateCompanyFormComponent
        onGoBack={this.handleGoBack}
        onSubmitForm={this.handleSubmitForm}
        onChangeForm={this.handleChangeForm}
        {...{ form, errors }}
      />
    );
  };

  renderData = () => {
    const { FSM, selectedCompany } = this.state;
    const { classes } = this.props;
    if (FSM === FSM_STATES.congratulation) {
      return <Congratulations selectedCompany={selectedCompany} />;
    }

    return (
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <div className={classes.logoContainer}>
            <Icon icon="welcome" />
          </div>
          <div className={classes.title}>Let's get you started!</div>
          {this.renderTextNotification()}
          {FSM === FSM_STATES.selectCompany && this.renderJoinToCompanyForm()}
          {FSM === FSM_STATES.createCompany && this.renderCreateCompanyForm()}
          {FSM === FSM_STATES.confirmCompany && this.renderConfirmCompanyForm()}
        </div>
      </div>
    );
  };

  render() {
    const { classes, loading } = this.props;

    return (
      <>
        <Helmet>
          <title>Vendor Sign-up</title>
        </Helmet>
        <div className={classes.root}>{loading ? <Loader /> : this.renderData()}</div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.ClaimCompanyReducer.loading,
  errors: state.ClaimCompanyReducer.errors,
  ...ownProps,
});

export default pipe(
  withStyles(styles),
  connect(mapStateToProps, {
    joinToCompany,
    loadCompanies,
    createCompany,
    compareEmailWithCompanyLink,
  }),
)(ClaimCompany);
