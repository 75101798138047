export default theme => ({
  fields: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -10px 32px',
    width: 'calc(100% + 20px)',
    padding: '0 25px',
  },
  field: {
    margin: '0 5px',
    flex: 1,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
  },
  cannotFind: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  createCompanyButton: {
    color: theme.palette.text.link,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 2,
    fontWeight: 700,
    letterSpacing: '0.02em',
  },
  claimButton: {
    width: 200,
  },
});
