import { set } from 'utils/Utils';
import ClaimCompanyConstants from './ClaimCompanyConstants';

const initialState = {
  loading: false,
  errors: {},
};

export default function ClaimCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case ClaimCompanyConstants.JOIN_USER_TO_CLAIM_COMPANY_START: {
      return set(state, ['loading'], true);
    }

    case ClaimCompanyConstants.JOIN_USER_TO_CLAIM_COMPANY_SUCCESS: {
      return set(state, ['loading'], false);
    }

    case ClaimCompanyConstants.JOIN_USER_TO_CLAIM_COMPANY_FAIL: {
      return set(state, ['loading'], false);
    }

    case ClaimCompanyConstants.CREATE_CLAIM_COMPANY_START: {
      return set(state, ['loading'], true);
    }

    case ClaimCompanyConstants.CREATE_CLAIM_COMPANY_SUCCESS: {
      return set(state, ['loading'], false);
    }

    case ClaimCompanyConstants.CREATE_CLAIM_COMPANY_FAIL: {
      return set(state, [], {
        loading: false,
        errors: action.errors,
      });
    }
    default:
      return state;
  }
}
