import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { isBlank } from 'utils/Utils';

import defaultOrganization from 'utils/DefaultOrganization';
import type { Organization } from 'presenters/OrganizationPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import useFeatureFlags from 'hooks/useFeatureFlags';
import styles from './styles';

interface LoginLogoProps extends WithStyles<typeof styles> {
  organization: Organization;
}

const LoginLogo: React.FC<LoginLogoProps> = ({ classes, organization }) => {
  const url = OrganizationPresenter.loginLogoUrl(organization);
  const fallbackUrl = OrganizationPresenter.loginLogoUrl(defaultOrganization as Organization);
  const useNewLogin = useFeatureFlags('ff_new_login') || gon.isPartner;
  const loginLogoUrl = (isBlank(url) ? fallbackUrl : url) || '';

  if (useNewLogin && organization.id === defaultOrganization.id) {
    return <></>; // We don't want to display the login logo if the new login is enabled and the login logo is not set
  }

  return (
    <>
      <div className={classes.logoContainer}>
        <img
          src={loginLogoUrl}
          className={classes.root}
          alt={isBlank(url) ? 'LearnCommunity login logo' : `${OrganizationPresenter.name(organization)}'s logo`}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(LoginLogo);
