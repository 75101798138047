import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.white,
    minHeight: '100vh',
    width: '100vw',
  },
  bar: {
    height: 10,
    width: '100%',
    background: `linear-gradient(90deg, ${theme.palette.blue50} 0%, ${theme.palette.CHANGEME[2]} 27.7%, ${theme.palette.CHANGEME[3]} 80.05%, ${theme.palette.CHANGEME[4]} 100%)`,
    position: 'fixed',
    zIndex: 10,
  },
  body: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0',
    position: 'relative',
  },
  footer: {
    height: 85,
    backgroundColor: theme.palette.background.darkGray,
    textAlign: 'center',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    padding: 15,
  },
}));
