import React, { useCallback, useEffect, useState } from 'react';
import type { Theme } from '@material-ui/core/styles';
import { produce } from 'immer';
import { Flex } from '@instructure/ui-flex';
import { View } from '@instructure/ui-view';

import { useLocation } from 'react-use';
import CustomThemeProvider from 'layouts/CustomThemeProvider';
import LoginLogo from 'components/LoginLogo';
import Notifications from 'sharedContainers/Notifications';
import learnCommunityOrganization from 'utils/DefaultOrganization';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import { useResponsiveContext } from 'hooks/useResponsiveContext';
import pencilBackground from 'assets/pencil-background.jpg';
import InstructureLogo from 'assets/instructure-logo.svg?react';
import InstructureIcon from 'assets/instructure-icon.svg?react';
import Icon from '../../components/Icon';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import * as Routes from '../../Routes';
import useStyles from './useStyles';
import NavigationLink from './components/NavigationLink';

declare const gon: any;

const PreApplicationLayout = ({
  usePartnerLayout = false,
  children,
}: {
  usePartnerLayout: boolean;
  children: React.ReactNode;
}) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  const location = useLocation();

  const organization = gon.currentOrganization || learnCommunityOrganization;

  const themeOverride = useCallback(
    (theme: Theme) => {
      const color = OrganizationPresenter.headerBackgroundColor(organization)!;
      const textColor = OrganizationPresenter.headerTextColor(organization)!;

      return produce(theme, draftTheme => {
        /* eslint-disable no-param-reassign */
        Object.assign(draftTheme.palette.primary, {
          main: color,
          contrastText: textColor,
          light: color,
        });
        draftTheme.palette.navbarItem.selected = color;
        /* eslint-enable no-param-reassign */
      });
    },
    [organization],
  );

  return (
    <CustomThemeProvider themeOverride={themeOverride}>
      {useNewLogin || usePartnerLayout ? (
        <PreApplicationNewLayoutInner
          currentPage={location.pathname || ''}
          usePartnerLayout={usePartnerLayout}
          organization={organization}
        >
          {children}
        </PreApplicationNewLayoutInner>
      ) : (
        <PreApplicationLayoutInner organization={organization}>{children}</PreApplicationLayoutInner>
      )}
    </CustomThemeProvider>
  );
};

const PreApplicationNewLayoutInner = ({
  currentPage,
  usePartnerLayout,
  children,
  organization,
}: {
  currentPage: string;
  usePartnerLayout: boolean;
  children: React.ReactNode;
  organization: any;
}) => {
  const { isDesktop, isTablet, isMobile } = useResponsiveContext();
  const [desiredWidth, setDesiredWidth] = useState('60em');
  const [desiredHeight, setDesiredHeight] = useState('100%');

  useEffect(() => {
    if (isDesktop) {
      setDesiredWidth('50%');
      setDesiredHeight('100%');
    } else if (isMobile) {
      setDesiredWidth('100%');
      setDesiredHeight('100%');
    } else if (isTablet) {
      setDesiredWidth('40em');
      setDesiredHeight('40em');
    }
  }, [isMobile, isTablet, isDesktop]);

  // TODO When we upgrade to InstUI 10 (know it is fixed in 10) we can remove the ts-expect-errors below..

  return (
    <>
      <Notifications />
      <View as="div" padding="small" background="secondary" position="absolute" width="100vw">
        <Flex as="div" direction="row">
          <View data-node="icon-wrapper" margin="none none none large">
            {usePartnerLayout ? <InstructureIcon /> : <Icon icon="brand-container" />}
          </View>
          {usePartnerLayout ? (
            <>
              <NavigationLink
                name="/partners/sign_in"
                currentName={currentPage}
                href={Routes.new_partner_session_path()}
                text="Sign In"
                dataNode="signin-page-link"
              />
              <NavigationLink
                name="/users/sign_up"
                currentName={currentPage}
                href={Routes.new_user_registration_path()}
                text="Become a Partner"
                dataNode="become-a-partner-page-link"
              />
            </>
          ) : (
            <>
              <NavigationLink
                name="/users/sign_in"
                currentName={currentPage}
                href={Routes.new_user_session_path()}
                text="Sign In"
                dataNode="signin-page-link"
              />
              <NavigationLink
                name="/users/sign_up"
                currentName={currentPage}
                href={Routes.new_user_registration_path()}
                text="Create Account"
                dataNode="create-account-page-link"
              />
            </>
          )}
        </Flex>
      </View>
      {isTablet && !isMobile ? (
        <div
          style={{
            backgroundImage: `url(${pencilBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
          }}
        >
          <Flex as="div" direction="column" height="100%" justifyItems="center" alignItems="center">
            <View data-node="content" as="div" minHeight={desiredHeight} width={desiredWidth} background="primary">
              <LoginLogo organization={organization} />
              <View data-node="child-wrapper" as="div" margin="small" padding="xx-large">
                {children}
              </View>
            </View>
            <View as="div" padding="large none medium">
              <InstructureLogo
                width="100%"
                style={{
                  color: 'white',
                  opacity: '72%', // opacity as designed
                }}
              />
            </View>
          </Flex>
        </div>
      ) : (
        <>
          <Flex
            as="div"
            justifyItems="start"
            alignItems="start"
            data-testid="margin-container"
            padding="xx-large none none none"
          >
            {/* @ts-expect-error */}
            <Flex.Item overflowX="visible" width={desiredWidth}>
              <LoginLogo organization={organization} />
              <Flex
                as="div"
                justifyItems="center"
                alignItems="center"
                data-node="child-wrapper"
                margin="small"
                padding="xx-large"
              >
                <View data-node="child-wrapper2" as="div" width="40em" padding="none">
                  {children}
                </View>
              </Flex>
              {/* @ts-expect-error */}
            </Flex.Item>

            {/* @ts-expect-error */}
            <Flex.Item shouldShrink shouldGrow overflowX="visible">
              <div
                style={{
                  backgroundImage: `url(${pencilBackground})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '100vh',
                }}
                data-node="background_image"
              />
              {/* @ts-expect-error */}
            </Flex.Item>
            <View position="fixed" insetBlockEnd="4.5rem" insetInlineEnd="5rem" data-node="logo-wrapper">
              <InstructureLogo
                width="100%"
                style={{
                  color: 'white',
                  opacity: '72%', // opacity as designed
                }}
              />
            </View>
          </Flex>
        </>
      )}
    </>
  );
};

/** Split out so that `useStyles()` runs under `<CustomThemeProvider>`. */
const PreApplicationLayoutInner = ({ children, organization }: { children: React.ReactNode; organization: any }) => {
  const classes = useStyles();
  return (
    <>
      <Notifications />
      <div className={classes.root}>
        <div className={classes.bar} />
        <div className={classes.body}>
          <LoginLogo organization={organization} />
          {children}
        </div>
      </div>
    </>
  );
};

export default PreApplicationLayout;
