export default theme => ({
  links: {
    display: 'flex',
    margin: 'auto',
  },

  linkToPrivacyPolicy: {
    color: theme.palette.gray50,
    fontSize: theme.typography.pxToRem(12),
  },

  pipe: {
    color: theme.palette.gray50,
    fontSize: theme.typography.pxToRem(12),
    padding: '0 10px 0 10px',
  },
  serviceLinks: {
    justifyContent: 'center',
    display: 'flex',
  },
});
