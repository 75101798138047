import keyMirror from 'keymirror';

export default keyMirror({
  JOIN_USER_TO_CLAIM_COMPANY_START: null,
  JOIN_USER_TO_CLAIM_COMPANY_SUCCESS: null,
  JOIN_USER_TO_CLAIM_COMPANY_FAIL: null,

  CREATE_CLAIM_COMPANY_START: null,
  CREATE_CLAIM_COMPANY_SUCCESS: null,
  CREATE_CLAIM_COMPANY_FAIL: null,

  COMPARE_CLAIM_COMPANY_DOMAIN_START: null,
  COMPARE_CLAIM_COMPANY_DOMAIN_SUCCESS: null,
  COMPARE_CLAIM_COMPANY_DOMAIN_FAIL: null,
});
