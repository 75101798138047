export default theme => ({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexGrow: 1,
    padding: '45px 0',
  },

  form: {
    width: 500,
  },

  footer: {
    display: 'flex',
    height: 85,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.text.light,
    color: theme.palette.text.secondary,
  },

  textFields: {
    textAlign: 'left',
    marginBottom: 30,
  },

  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    margin: '27px 0 40px',
  },

  backToLogin: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  linkBackToLogin: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    marginTop: 2,
    textDecoration: 'none',
  },

  resetButton: {
    width: 200,
  },

  emailTextField: {
    marginBottom: 0,
    '& label, p': {
      color: theme.palette.text.primary,
    },
  },
});
