import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';

import Icon from 'components/Icon';

import styles from './styles';

class SocialAuthButtons extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
  };

  render() {
    const { classes } = this.props;

    return (
      <form action={Routes.users_auth_passthru_google_oauth2_path({ mode: 'sign_up_oauth' })} method="post">
        <input type="hidden" name="authenticity_token" value={authenticityToken()} />
        <div className={classes.label} />
        <Button size="large" type="submit" className={classes.button}>
          <Icon icon="google" className={classes.icon} />
          Sign in with Google
        </Button>
      </form>
    );
  }
}

export default withStyles(styles)(SocialAuthButtons);
