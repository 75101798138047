export default theme => ({
  formContainer: {
    display: 'flex',
    textAlign: 'center',
    flexGrow: 1,
    paddingBottom: 45,
  },

  form: {
    width: 500,
  },

  textFields: {
    textAlign: 'left',
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&>div': {
      width: '100%',
    },
  },

  textField: {
    '& label, p': {
      color: theme.palette.text.primary,
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 600,
    margin: '30px 0 40px',
  },

  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    top: '-20px',
  },

  linkForgotPassword: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    marginTop: 2,
    textDecoration: 'none',
  },

  passwordTextField: {
    marginBottom: '-5px',
  },

  checkbox: {
    margin: 0,
    '& span': {
      padding: '0 2px 0 0',
      fontSize: theme.typography.pxToRem(12),
    },
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 25,
  },

  button: {
    flex: 1,

    '&:first-child': {
      marginRight: 5,
    },
    '&:last-child': {
      marginLeft: 5,
    },
  },
});
