export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.text.white,
  },

  formContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'center',
    flexGrow: 1,
  },

  form: {
    width: 650,
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(30),
    margin: '30px 0 15px',
    color: theme.palette.text.primary,
  },

  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },

  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});
