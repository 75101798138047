import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { map, pathOr } from 'ramda';
import PropTypes from 'prop-types';

import { IconAdminLine, IconEyeLine } from '@instructure/ui-icons';
import { Flex, Text, Link, View, Button, TextInput, IconButton, FormFieldGroup } from '@instructure/ui';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import Flash from 'components/Flash';
import IUSocialAuthButtons from 'components/IUSocialAuthButtons';

const IUSignIn = ({ user, isPartner }) => {
  const [email, setEmail] = useState(pathOr('', ['email'], user));
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [notifications, setNotifications] = useState(gon.flash);

  const handleChange = field => e => {
    if (field === 'email') setEmail(e.target.value);
    if (field === 'password') setPassword(e.target.value);
  };

  useEffect(() => {
    setNotifications(gon.flash);
  }, [gon.flash]);

  const appName = isPartner ? 'the Partner Portal' : 'LearnPlatform';

  const renderFlash = flash => <Flash key={flash.message} flash={flash} />;

  return (
    <>
      <Helmet>
        <title>{appName}</title>
      </Helmet>
      <Text weight="bold" size="xx-large">
        Welcome to {appName}
      </Text>
      {map(renderFlash, notifications)}
      <View padding="xx-large none none none" as="form" action={Routes.new_user_session_path()} method="post">
        <FormFieldGroup description="" layout="stacked" rowSpacing="medium">
          <input type="hidden" name="authenticity_token" value={authenticityToken()} />

          <TextInput
            name="user[email]"
            renderLabel={
              <View>
                <Text>Email Address</Text>
              </View>
            }
            value={email}
            isRequired
            onChange={handleChange('email')}
            interaction="enabled"
            data-node="user_email_field"
          />

          <TextInput
            name="user[password]"
            type={showPassword ? 'text' : 'password'}
            renderLabel={
              <View>
                <Text>Password</Text>
              </View>
            }
            value={password}
            isRequired
            onChange={handleChange('password')}
            interaction="enabled"
            renderAfterInput={
              <IconButton
                shape="circle"
                size="small"
                withBorder={false}
                withBackground={false}
                screenReaderLabel="Show password"
                onClick={() => setShowPassword(!showPassword)}
                renderIcon={showPassword ? IconAdminLine : IconEyeLine}
                data-node="login-show-password-button"
              />
            }
            data-node="user_password_field"
          />
          <Button
            type="submit"
            display="block"
            color="primary"
            textAlign="center"
            interaction="enabled"
            data-node="login-submit-button"
          >
            Log In
          </Button>

          <Flex alignItems="center" justifyItems="center">
            <Link href={Routes.new_user_password_path()} isWithinText={false} data-node="login-forgot-password-button">
              Forgot Password?
            </Link>
          </Flex>
        </FormFieldGroup>
      </View>

      <IUSocialAuthButtons />
    </>
  );
};

IUSignIn.propTypes = {
  user: PropTypes.shape(),
  isPartner: PropTypes.bool,
};

export default IUSignIn;
