import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    padding: 50,
    maxWidth: 770,
    marginBottom: 50,
    '&>div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },

  icon: {
    marginBottom: 50,
  },

  title: {
    margin: `0 0 ${theme.spacing(2)}`,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(30),
    fontWeight: 'bold',
  },

  text: {
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 100,
    fontSize: theme.typography.pxToRem(12),
  },

  hint: {
    fontSize: theme.typography.pxToRem(12),
    margin: '0 0 50px',
  },

  email: {
    fontWeight: 'bold',
  },

  footer: {
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: theme.typography.pxToRem(30),
  },

  link: {
    fontWeight: 600,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
