import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },

  title: {
    margin: '24px 0 40px',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(30),
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  button: {
    fontWeight: 700,
    textDecoration: 'none',
    width: 200,
  },

  form: {
    textAlign: 'center',
    width: 405,
  },

  backToLogin: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  },

  textField: {
    marginBottom: 0,
    marginTop: 25,
    '& label, p': {
      color: theme.palette.text.primary,
    },
  },

  linkBackToLogin: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: 2,
    fontWeight: 700,
    textDecoration: 'none',
  },
}));
