import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  caption: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: 15,
    color: theme.palette.text.primary,
  },
  companyName: {
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),
    marginBottom: 35,
  },
  buttons: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
  },
  claimButton: {
    width: 200,
    marginBottom: 25,
  },
}));
