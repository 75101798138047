import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AutocompleteAsync from 'components/AutocompleteAsync';
import FormGroup from 'components/FormGroup';
import Form from 'forms/ClaimCompany/JoinToCompanyForm';
import { set, isBlank } from 'utils/Utils';
import styles from './styles';

class JoinToCompanyForm extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    selectedCompany: PropTypes.shape().isRequired,
    onLoadCompanies: PropTypes.func.isRequired,
    onSubmitForm: PropTypes.func.isRequired,
    onChangeSelectedCompany: PropTypes.func.isRequired,
    onCantFindCompany: PropTypes.func.isRequired,
  };

  state = {
    form: Form.defaultAttributes({ companyId: this.props.selectedCompany.id }),
    errors: [],
  };

  handleChangeForm = company => {
    this.setState(set(this.state, ['form', 'companyId'], company.id));
    this.props.onChangeSelectedCompany(company);
  };

  handleSubmitForm = () => {
    const { form } = this.state;
    const errors = Form.validate(form);

    if (!isBlank(errors)) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: {} });

    this.props.onSubmitForm(form);
  };

  render() {
    const {
      classes,
      selectedCompany,
      selectedCompany: { name: companyName },
    } = this.props;

    const { errors } = this.state;

    return (
      <>
        <FormGroup>
          <AutocompleteAsync
            getOptions={this.props.onLoadCompanies}
            placeholder="Search for your company"
            dataNode="company_field"
            onAdd={this.handleChangeForm}
            startIcon="search"
            disableUnderline
            endIcon="chevron-down"
            className={classes.autocomplete}
            fullWidthMenu
            value={companyName}
            saveValue
            helperText={errors.companyId && errors.companyId}
            error={errors.companyId}
          />
        </FormGroup>

        <Grid spacing={1} container className={classes.gridPadding}>
          <Grid spacing={3} item container>
            <Grid item xs={12} sm={6} container direction="row" justify="flex-end" alignItems="center">
              <Button
                data-node="link_back_to_find"
                onClick={this.props.onCantFindCompany}
                className={classes.createCompanyButton}
              >
                I Cannot Find My Company
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} container direction="row" justify="flex-start" alignItems="center">
              <Button
                data-node="claim_button"
                size="middle"
                variant="contained"
                color="secondary"
                className={classes.claimButton}
                type="submit"
                onClick={this.handleSubmitForm}
                disabled={isEmpty(selectedCompany)}
              >
                Join Your Company
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(JoinToCompanyForm);
