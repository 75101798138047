import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `0 ${theme.spacing(4)}`,
  },

  form: {
    paddingBottom: 50,
  },

  title: {
    margin: '30px 0 15px',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(30),
    fontWeight: 600,
  },

  description: {
    margin: `0 0 ${theme.spacing(3)}`,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
  },

  backButton: {
    position: 'absolute',
    top: 37,
    left: 50,
    fontWeight: 700,
    zIndex: 1,

    '& span': {
      display: 'flex',
      alignItems: 'center',
    },

    '&:hover, &:focus': {
      color: theme.lighten(theme.palette.text.link, 0.5),
      backgroundColor: 'transparent',
    },
  },

  arrow: {
    marginRight: theme.spacing(1.5),
    width: 20,
    height: 20,
    [theme.breakpoints.up('postmd')]: {
      marginRight: theme.spacing(1.5),
      width: 20,
      height: 40,
    },
  },

  formContainer: {
    display: 'flex',
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
  },

  signIn: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },

  serviceLinks: {
    marginTop: '20px',
    marginBottom: '10px',
    marginRight: '15px',
  },

  footerLink: {
    fontWeight: 700,
    textDecoration: 'none',
  },
}));
