import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  table: {
    width: 725,
    marginBottom: 30,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 4,
  },

  organizationHeader: {
    borderRadius: '4px 0 0 0',
  },

  actionsHeader: {
    borderRadius: '0 4px 0 0',
  },

  tableAvatar: {
    marginRight: 15,
  },

  tableRemoveLink: {
    color: theme.palette.danger,
    fontWeight: 'normal',
    padding: 0,
  },

  tableConfirmLink: {
    padding: 0,
    marginRight: 15,
  },

  confirmation: {
    display: 'flex',
    flexDirection: 'column',
  },

  actions: {
    width: 180,
    textAlign: 'right',
  },
}));
