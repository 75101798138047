import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Routes from 'Routes';

import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';

import useStyles from './useStyles';

const PasswordFields = ({ backToLogin, buttonText, submitButton, className }) => {
  const classes = useStyles();
  const [password, updatePassword] = useState('');
  const [confirmPassword, updateConfirmPassword] = useState('');
  const [checkLength, setCheckLength] = useState(false);

  return (
    <>
      <FormGroup className={className}>
        <TextField
          data-node="password"
          onChange={e => updatePassword(e.target.value)}
          onFocus={() => setCheckLength(false)}
          onBlur={() => setCheckLength(true)}
          name="user[password]"
          value={password}
          className={classes.textField}
          id="user_password"
          label="New Password"
          type="password"
          fullWidth
          autoComplete="new-password"
          variant="filled"
          error={checkLength && password.length < 8}
          helperText={checkLength && password.length < 8 ? 'Password must be at least 8 characters' : 'Required*'}
        />
        <TextField
          data-node="confirm_password"
          onChange={e => updateConfirmPassword(e.target.value)}
          name="user[password_confirmation]"
          value={confirmPassword}
          className={classes.textField}
          id="user_confirm_password"
          label="Confirm New Password"
          type="password"
          fullWidth
          autoComplete="new-password"
          variant="filled"
          error={!!confirmPassword && confirmPassword !== password}
          helperText={!!confirmPassword && confirmPassword !== password ? 'Passwords must match' : 'Required*'}
        />
        {backToLogin && (
          <div className={classes.backToLogin}>
            <Button variant="link" href={Routes.new_user_session_path()} className={classes.linkBackToLogin}>
              Back to Login
            </Button>
          </div>
        )}
      </FormGroup>
      {submitButton && (
        <Button
          data-node="set_password_button"
          type="submit"
          className={classes.button}
          disabled={!password || !confirmPassword || password.length < 8 || password !== confirmPassword}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

PasswordFields.propTypes = {
  backToLogin: PropTypes.bool,
  buttonText: PropTypes.string,
  submitButton: PropTypes.bool,
  className: PropTypes.string,
};

PasswordFields.defaultProps = {
  backToLogin: false,
  buttonText: 'Set Password',
  submitButton: true,
  className: null,
};

export default PasswordFields;
