import * as Yup from 'yup';
import { yupToFormErrors } from 'utils/Utils';
import { decamelize } from 'utils/keysConverter';

const JoinToCompanySchema = Yup.object().shape({
  companyId: Yup.number().nullable().required("Can't be blank. Select an option.").label('Company'),
});

export default {
  defaultAttributes({ companyId }) {
    return {
      companyId,
    };
  },

  validate(form) {
    try {
      JoinToCompanySchema.validateSync(form, { abortEarly: false });
      return {};
    } catch (err) {
      return yupToFormErrors(err);
    }
  },

  attributesToSubmit(form) {
    return decamelize(form);
  },
};
