import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.primary,
    width: 730,
  },

  title: {
    margin: '24px 0 15px',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(30),
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  description: {
    marginBottom: 25,
  },

  memberships: {
    margin: '20px auto',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 700,
  },

  button: {
    fontWeight: 700,
    textDecoration: 'none',
    minWidth: 200,
    marginBottom: 50,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  radioButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0 50px',
  },

  radioButton: {
    padding: '12px 25px 11px',
    margin: '0 2.5px',
    borderColor: theme.palette.borderDark,
    color: theme.palette.text.primary,
    fontWeight: 400,
    minWidth: 'unset',
    height: 40,
    letterSpacing: 0,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.buttons.visibilityToggle.active.backgroundColor,
      color: theme.palette.buttons.visibilityToggle.active.color,
      borderColor: theme.palette.buttons.visibilityToggle.active.color,
    },
  },

  radioButtonActive: {
    backgroundColor: theme.palette.buttons.visibilityToggle.active.backgroundColor,
    color: theme.palette.buttons.visibilityToggle.active.color,
    borderColor: theme.palette.buttons.visibilityToggle.active.color,
    fontWeight: 700,
  },

  textFieldContainer: {
    width: 638,
    display: 'flex',
    justifyContent: 'space-between',
  },

  textField: {
    marginBottom: 0,
    width: 306,
    '& label, p': {
      color: theme.palette.textField.filled.text,
    },
  },

  autocomplete: {
    '&>div:first-child>div': {
      height: 56,
      backgroundColor: theme.palette.textField.filled.background,
      margin: 0,
      borderRadius: '4px 4px 0 0',
    },
    '&>div:nth-child(2)>div': {
      height: 300,
    },
    '&>div>label': {
      zIndex: 1,
      padding: '0 15px',
      top: '-2px',
    },
    '&>div>label.Mui-focused': {
      top: 5,
    },
    '&>div:first-child>div>input': {
      padding: '0 15px',
      position: 'relative',
      top: 8,
    },
  },

  autocompleteFilled: {
    '&>div>label': {
      top: 5,
    },
  },

  autocompleteIcon: {
    width: 37,
  },

  tagSelectContainer: {
    width: 638,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '50px 0',
  },

  tagContainer: {
    maxWidth: 306,
  },

  tagSelect: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: theme.palette.text.light,
    height: 50,
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    width: 306,
    marginBottom: 10,
    padding: 15,
    '&:hover': {
      backgroundColor: theme.darken(theme.palette.text.light, 0.1),
      borderBottom: `1px solid ${theme.darken(theme.palette.text.disabled, 0.2)}`,
    },
    '& span': {
      justifyContent: 'space-between',
    },
  },

  chip: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.borderGray}`,
  },
}));
