import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'ramda';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from './styles';

class ConfirmCompany extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    selectedCompany: PropTypes.shape().isRequired,
    onSubmitForm: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
  };

  render() {
    const { classes, selectedCompany } = this.props;

    return (
      <>
        <Grid spacing={3} container className={classes.gridPadding} alignItems="center" justify="center">
          <Grid item>
            <Button onClick={this.props.onGoBack} className={classes.createCompanyButton}>
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-node="claim_button"
              size="middle"
              variant="contained"
              color="secondary"
              className={classes.claimButton}
              type="submit"
              onClick={this.props.onSubmitForm}
              disabled={isEmpty(selectedCompany)}
            >
              Request Access
            </Button>
          </Grid>
          <Grid item>
            <Button href="mailto:support-lp@instructure.com" className={classes.createCompanyButton}>
              Contact Support
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(ConfirmCompany);
