import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import appRoutes from 'appRoutes';
import styles from './styles';

const TermsAndPrivacyLinks = ({ classes }) => (
  <div className={classes.serviceLinks}>
    <div className={classes.links}>
      <div className={classes.linkToPrivacyPolicy}>
        <a href={appRoutes.learnPlatformTermsOfService()} className={classes.linkToPrivacyPolicy}>
          Terms of Service
        </a>
      </div>
      <div className={classes.pipe}>&#124;</div>
      <div className={classes.linkToPrivacyPolicy}>
        <a href={appRoutes.learnPlatformPrivacyPolicy()} className={classes.linkToPrivacyPolicy}>
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
);

TermsAndPrivacyLinks.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(TermsAndPrivacyLinks);
