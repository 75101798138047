import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead } from '@material-ui/core';
import TableCell from 'components/TableCell';
import TableRow from 'components/TableRow';
import TableBody from 'components/TableBody';
import StatusLabel from 'components/StatusLabel';
import Button from 'components/Button';
import Loader from 'components/Loader';
import NoData from 'components/NoData';

import OrganizationAvatar from 'components/OrganizationAvatar';
import MembershipPresenter from 'presenters/MembershipPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import useStyles from './useStyles';

const MembershipTable = ({ memberships, onDestroy, loading }) => {
  const classes = useStyles();
  const [deleting, updateDeleting] = useState(null);

  const handleDestroy = membershipId => onDestroy(membershipId);

  const renderDeleteConfirmation = id => {
    return (
      <TableCell className={`${classes.actions} ${classes.confirmation}`}>
        Remove membership?
        <div>
          <Button variant="text" size="thin" className={classes.tableConfirmLink} onClick={() => updateDeleting(null)}>
            Cancel
          </Button>
          <Button variant="text" size="thin" className={classes.tableRemoveLink} onClick={() => handleDestroy(id)}>
            Remove
          </Button>
        </div>
      </TableCell>
    );
  };

  const renderActions = membership => {
    return (
      <TableCell className={classes.actions}>
        <Button variant="text" className={classes.tableRemoveLink} onClick={() => updateDeleting(membership.id)}>
          Remove
        </Button>
      </TableCell>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }

    if (!memberships || memberships.length === 0) {
      return <NoData type="table" colSpan={3} />;
    }

    return memberships.map(membership => {
      const organization = MembershipPresenter.organization(membership);
      return (
        <Fragment key={organization.id}>
          <TableRow className={classes.row} data-node="member_rows">
            <TableCell>
              <OrganizationAvatar organization={organization} size="sm" className={classes.tableAvatar} />
              {OrganizationPresenter.name(organization)}
            </TableCell>
            <TableCell>
              <StatusLabel status={MembershipPresenter.statusObject(membership)} type="dot" />
            </TableCell>
            {deleting === membership.id ? renderDeleteConfirmation(membership.id) : renderActions(membership)}
          </TableRow>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Table className={classes.table} id="membership_table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.organizationHeader}>Organization</TableCell>
            <TableCell>Status</TableCell>
            <TableCell className={classes.actionsHeader} />
          </TableRow>
        </TableHead>
        <TableBody data-node="membership_table">{renderContent()}</TableBody>
      </Table>
    </>
  );
};

MembershipTable.propTypes = {
  memberships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDestroy: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default MembershipTable;
