import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { pipe, isEmpty, join } from 'ramda';
import * as Routes from 'Routes';

import Button from 'components/Button';
import Icon from 'components/Icon';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import Flash from 'components/Flash';

import { authenticityToken } from 'utils/FetchHelpers';

import styles from './styles';

class ForgotPassword extends PureComponent {
  static propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    classes: PropTypes.shape().isRequired,
  };

  state = {
    email: '',
  };

  handleChange = field => e => {
    this.setState({ [field]: e.target.value });
  };

  handleSubmit = e => {
    const form = e.target.elements;
    form.authenticity_token.value = authenticityToken();
  };

  renderFlash = () => {
    const { errors } = this.props;

    return !isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />;
  };

  render() {
    const { classes } = this.props;
    const { email } = this.state;

    return (
      <>
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <Icon icon="forgotPassword" />
            <div className={classes.title}>Forgot password? No biggie!</div>
            {this.renderFlash()}

            <form action={Routes.user_password_path()} onSubmit={this.handleSubmit} method="post">
              <input type="hidden" name="authenticity_token" />
              <div className={classes.textFields}>
                <FormGroup>
                  <TextField
                    data-node="user_email_input"
                    onChange={this.handleChange('email')}
                    name="user[email]"
                    value={email}
                    className={classes.emailTextField}
                    variant="filled"
                    label="Email"
                    placeholder="Enter email address"
                    autoComplete="email"
                    id="user_email"
                  />
                  <div className={classes.backToLogin}>
                    <Button variant="link" href={Routes.new_user_session_path()} className={classes.linkBackToLogin}>
                      Back to Login
                    </Button>
                  </div>
                </FormGroup>
              </div>
              <Button data-node="reset_password_button" color="secondary" className={classes.resetButton} type="submit">
                Reset Password
              </Button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default pipe(withStyles(styles))(ForgotPassword);
