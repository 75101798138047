export default theme => ({
  root: {
    padding: `20px ${theme.spacing(2)}`,
    height: 175,
    width: 200,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 8,
    overflow: 'hidden',
    '&:hover, &:focus': {
      height: '100%',
      borderColor: theme.palette.text.link,
      boxShadow: `0 10px 10px 0 ${theme.palette.CHANGEME[45]}`,
      outline: 'none',
    },
    '& span': {
      display: 'inline-block',
      textAlign: 'left',
    },
  },

  rootActive: {
    borderColor: theme.palette.text.link,
    boxShadow: `0 10px 10px 0 ${theme.palette.CHANGEME[45]}`,
    height: '100%',
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    margin: '20px 0 30px 0',
  },

  list: {
    margin: 0,
    padding: '0 0 0 10px',
  },

  listItem: {
    margin: '10px 0',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
});
