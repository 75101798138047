import * as Yup from 'yup';
import { yupToFormErrors } from 'utils/Utils';
import { decamelize } from 'utils/keysConverter';

const CreateNewCompanyForm = Yup.object().shape({
  name: Yup.string().required().label('Company Name'),
  url: Yup.string().url().required().label('Company URL'),
});

export default {
  defaultAttributes() {
    return {
      name: '',
      url: '',
    };
  },

  validate(form) {
    try {
      CreateNewCompanyForm.validateSync(form, { abortEarly: false });
      return {};
    } catch (err) {
      return yupToFormErrors(err);
    }
  },

  attributesToSubmit(form) {
    const data = {
      name: form.name,
      companyUrl: form.url,
    };
    return decamelize(data);
  },
};
