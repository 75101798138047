export default () => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  logoContainer: {
    width: 146,
    maxHeight: 112,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '24px auto 40px',
  },
});
