export default theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
  },
  cannotFind: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  createCompanyButton: {
    color: theme.palette.text.link,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 2,
    fontWeight: 700,
    letterSpacing: '0.02em',
  },
  claimButton: {
    width: 200,
  },
  gridPadding: {
    marginTop: '-7px',
  },
  autocomplete: {
    '& label + div': {
      marginTop: 0,
      padding: 10,
      border: `1px solid ${theme.palette.border}`,
      borderRadius: 5,
    },
  },
});
