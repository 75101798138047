import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { pathOr } from 'ramda';
import appRoutes from 'appRoutes';
import useStyles from './useStyles';

const Notification = ({ fsm, fsmStates, selectedCompany }) => {
  const classes = useStyles();
  const name = pathOr('', ['name'], selectedCompany);

  if (fsm === fsmStates.requestingAccess) {
    return (
      <>
        <div className={classes.caption}>
          Access for <span className={classes.companyName}>{name}</span> has been requested from the company!
          <br />
          Check your email for further information.
        </div>
        <div className={classes.buttons}>
          <Button
            data-node="got_it_button"
            size="middle"
            variant="contained"
            color="secondary"
            className={classes.claimButton}
            href={appRoutes.learnCommunityPath()}
          >
            Got it!
          </Button>
        </div>
      </>
    );
  }

  if (fsm === fsmStates.confirmCompany) {
    return (
      <>
        <div className={classes.caption}>
          Oops! It seems that your email address does not match what we have on record for that company.
          <br />
          Would you like to request access for that email from the company?
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.caption}>Please join your company to continue to your dashboard.</div>
      <div className={classes.text}>
        Joining your company will allow you to respond to RFPs from districts around the country and manage your
        products' details and presentation in LearnPlatform and other sites that use the LearnPlatform library.
      </div>
    </>
  );
};

Notification.propTypes = {
  fsm: PropTypes.string.isRequired,
  fsmStates: PropTypes.shape().isRequired,
  selectedCompany: PropTypes.shape().isRequired,
};

export default Notification;
