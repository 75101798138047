import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    margin: '24px 0 40px',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(30),
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    textAlign: 'center',
    width: 405,
  },
  backButton: {
    position: 'absolute',
    top: 37,
    left: 50,
    fontWeight: 700,
    zIndex: 1,

    '& span': {
      display: 'flex',
      alignItems: 'center',
    },

    '&:hover, &:focus': {
      color: theme.lighten(theme.palette.text.link, 0.5),
      backgroundColor: 'transparent',
    },
  },
  arrow: {
    marginRight: theme.spacing(1.5),
    width: 20,
    height: 20,
    [theme.breakpoints.up('postmd')]: {
      marginRight: theme.spacing(1.5),
      width: 20,
      height: 40,
    },
  },
}));
