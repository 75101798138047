import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isNil } from 'ramda';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { isBlank } from 'utils/Utils';
import { URL_DEFAULT_PROTOCOL_STRING } from 'presenters/OrganizationTool/ResourcePresenter';
import styles from './styles';

class CreateCompanyForm extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    onSubmitForm: PropTypes.func.isRequired,
    form: PropTypes.shape(),
    onGoBack: PropTypes.func.isRequired,
    errors: PropTypes.shape(),
    onChangeForm: PropTypes.func,
  };

  render() {
    const {
      classes,
      form: { name, url },
      onChangeForm,
      onSubmitForm,
      errors,
    } = this.props;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="company-name"
              label="Company Name"
              type="text"
              name="company-name"
              fullWidth
              onChange={onChangeForm('name')}
              value={name}
              error={!(isBlank(errors.companyName) && isBlank(errors.name))}
              helperText={isBlank(errors.name) ? 'Required Field *' : errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="company-url"
              label="Company URL"
              placeholder={URL_DEFAULT_PROTOCOL_STRING}
              type="text"
              name="company-url"
              fullWidth
              onChange={onChangeForm('url')}
              value={url}
              error={!isNil(errors.url)}
              helperText={isNil(errors.url) ? 'Required Field *' : errors.url}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.cannotFind}>
              <Button onClick={this.props.onGoBack} className={classes.createCompanyButton}>
                Go Back
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} container direction="row" justify="flex-start" alignItems="center">
            <Button
              data-node="claim_button"
              size="middle"
              variant="contained"
              color="secondary"
              className={classes.claimButton}
              type="submit"
              onClick={onSubmitForm}
            >
              Create Your Company
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(CreateCompanyForm);
