import { useContext } from 'react';
import { ResponsiveContext, type ResponsiveContextType } from '../contexts/ResponsiveContext';

export const useResponsiveContext = () => {
  const context = useContext<ResponsiveContextType>(ResponsiveContext);

  if (context === undefined) {
    throw new Error('useResponsiveContext must be used within a ResponsiveContext.Provider');
  }

  return context;
};

export default useResponsiveContext;
