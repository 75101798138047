export default theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
  },
  cannotFind: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  createCompanyButton: {
    color: theme.palette.text.link,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 2,
    fontWeight: 700,
    letterSpacing: '0.02m',
  },
  gridPadding: {
    marginTop: 20,
  },
  claimButton: {
    width: 200,
  },
});
