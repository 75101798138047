import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    fontWeight: 700,
    textDecoration: 'none',
    width: 200,
  },

  textField: {
    marginBottom: 0,
    marginTop: 25,
    '& label, p': {
      color: theme.palette.text.primary,
    },
  },

  linkBackToLogin: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: 2,
    fontWeight: 700,
    textDecoration: 'none',
  },

  backToLogin: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    top: '-20px',
  },
}));
