import React, { type ReactNode, type ReactElement, useEffect, useState } from 'react';
import { Responsive } from '@instructure/ui-responsive';
import { useResponsiveContext } from 'hooks/useResponsiveContext';
import type { ResponsiveQueries, QueriesMatching } from './ResponsiveContext';
import { ResponsiveContext } from './ResponsiveContext';

export type ResponsiveContextProviderProps = {
  currentMatches?: QueriesMatching;
  query: ResponsiveQueries;
  children: ReactNode | ReactElement;
};

export const ResponsiveContextProvider = ({ currentMatches, query, children }: ResponsiveContextProviderProps) => {
  const [matches, setMatches] = useState(currentMatches || []);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setMatches(currentMatches || []);
    setIsDesktop(currentMatches?.includes('desktop') || false);
    setIsTablet(currentMatches?.includes('tablet') || false);
    setIsMobile(currentMatches?.includes('mobile') || false);
  }, [currentMatches]);

  return (
    <ResponsiveContext.Provider
      value={{
        query,
        matches,
        isDesktop,
        isTablet,
        isMobile,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

export type ResponsiveProviderProps = {
  children: ReactNode | ReactElement;
};

export const ResponsiveProvider = ({ children }: ResponsiveProviderProps) => {
  const { query } = useResponsiveContext();
  return (
    <Responsive match="media" query={query}>
      {(_props, matches) => {
        return (
          <ResponsiveContextProvider currentMatches={(matches as QueriesMatching) || []} query={query}>
            {children}
          </ResponsiveContextProvider>
        );
      }}
    </Responsive>
  );
};

export default ResponsiveProvider;
