import ClaimRepository from 'repositories/Companies/ClaimRepository';
import RolesRepository from 'repositories/Organizations/RolesRepository';
import CheckCompanyDomainsRepository from 'repositories/Companies/CheckCompanyDomainsRepository';
import { errorMessages } from 'utils/ErrorHelpers';
import ClaimCompanyConstants from './ClaimCompanyConstants';

export function loadCompanies(params) {
  return () => ClaimRepository.index(params);
}

export function joinToCompany(organizationId) {
  return dispatch => {
    dispatch({ type: ClaimCompanyConstants.JOIN_USER_TO_CLAIM_COMPANY_START });

    return RolesRepository.create(organizationId)
      .then(response => {
        dispatch({ type: ClaimCompanyConstants.JOIN_USER_TO_CLAIM_COMPANY_SUCCESS, response });
        return response;
      })
      .catch(exception => {
        dispatch({ type: ClaimCompanyConstants.JOIN_USER_TO_CLAIM_COMPANY_FAIL, exception });
        throw exception;
      });
  };
}

export function compareEmailWithCompanyLink(params) {
  return dispatch => {
    dispatch({ type: ClaimCompanyConstants.COMPARE_CLAIM_COMPANY_DOMAIN_START });

    return CheckCompanyDomainsRepository.show(params)
      .then(response => {
        dispatch({ type: ClaimCompanyConstants.COMPARE_CLAIM_COMPANY_DOMAIN_SUCCESS, response });
        return response;
      })
      .catch(exception => {
        dispatch({ type: ClaimCompanyConstants.COMPARE_CLAIM_COMPANY_DOMAIN_FAIL, exception });
        throw exception;
      });
  };
}

export function createCompany(params) {
  return dispatch => {
    dispatch({ type: ClaimCompanyConstants.CREATE_CLAIM_COMPANY_START });
    return ClaimRepository.create(params)
      .then(response => {
        dispatch({ type: ClaimCompanyConstants.CREATE_CLAIM_COMPANY_SUCCESS, response });
        return response;
      })
      .catch(exception => {
        const errors = errorMessages(exception.errors);
        dispatch({ type: ClaimCompanyConstants.CREATE_CLAIM_COMPANY_FAIL, errors });
        throw exception;
      });
  };
}
