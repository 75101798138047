import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Button from 'components/Button';
import styles from './styles';

class Role extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    listItems: PropTypes.arrayOf(PropTypes.node),
    active: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    classes: PropTypes.shape().isRequired,
  };

  handleSelect = () => {
    const { onSelect, id } = this.props;
    onSelect(id);
  };

  render() {
    const { classes, active, image, title, listItems, disabled, id } = this.props;

    const roleStyles = cn(classes.root, {
      [classes.rootActive]: active,
    });

    return (
      <Button
        className={roleStyles}
        color="transparent"
        onClick={this.handleSelect}
        data-node={`select_${id}_role`}
        disabled={disabled}
      >
        <div className={classes.main}>
          <div className={classes.content}>
            <img src={image} className={classes.image} alt={title} />
            <h2 className={classes.title}>{title}</h2>

            <ul className={classes.list}>
              {listItems.map(item => (
                <li key={item} className={classes.listItem}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Button>
    );
  }
}

export default withStyles(styles)(Role);
