import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const url = Routes.api_v2_confirmations_path();

    return FetchHelpers.post(url, params);
  },
};
