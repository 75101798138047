export default theme => ({
  button: {
    padding: 10,
    fontWeight: 700,
    boxShadow: `0 0 1px 0 ${theme.palette.CHANGEME[30]}, 0 1px 1px 0 ${theme.palette.CHANGEME[35]} !important`,
    color: theme.palette.text.secondary,
  },

  icon: {
    marginRight: 21,
  },

  label: {
    width: 280,
    height: 2,
    background: theme.palette.terms,
    margin: '0 auto 25px',
    position: 'relative',

    '&::after': {
      content: "'or'",
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
      background: theme.palette.common.white,
      padding: '0 4px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
});
