import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isEmpty, join } from 'ramda';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Flash from 'components/Flash';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';

import useStyles from './useStyles';

const ResendConfirmation = ({ errors }) => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Resend Confirmation Instructions</title>
      </Helmet>
      <div className={classes.form}>
        <Icon icon="forgotPassword" />
        <div className={classes.title}>Resend confirmation instructions</div>
        {!isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />}
        <form
          action={Routes.user_confirmation_path()}
          method="post"
          onSubmit={e => {
            const form = e.target.elements;
            form.authenticity_token.value = authenticityToken();
          }}
          className={classes.form}
        >
          <input type="hidden" name="authenticity_token" />
          <FormGroup>
            <TextField
              data-node="email"
              name="user[email]"
              className={classes.textField}
              id="user_email"
              label="Email"
              placeholder="Enter email address"
              fullWidth
              autoComplete="email"
              variant="filled"
            />
            <div className={classes.backToLogin}>
              <Button variant="link" href={Routes.new_user_session_path()} className={classes.linkBackToLogin}>
                Back to Login
              </Button>
            </div>
          </FormGroup>
          <Button data-node="resend_confirmation_button" type="submit" className={classes.button}>
            Send Confirmation
          </Button>
        </form>
      </div>
    </>
  );
};

ResendConfirmation.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default ResendConfirmation;
