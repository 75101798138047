import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import appRoutes from 'appRoutes';
import { redirectWithReloadPage } from 'utils/LocationHelpers';
import SuccessLogo from '../../assets/success_logo.svg';
import styles from './styles';

class Congratulations extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    selectedCompany: PropTypes.shape().isRequired,
  };

  redirectToCompany = () => {
    const {
      selectedCompany: { organizationId },
    } = this.props;
    const path = appRoutes.organizationPath(organizationId);
    return redirectWithReloadPage(path);
  };

  render() {
    const {
      classes,
      selectedCompany: { name },
    } = this.props;
    return (
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <div className={classes.logoContainer}>
            <img src={SuccessLogo} className={classes.logo} alt="Logo" />
          </div>
          <div className={classes.title}>Congratulations!</div>
          <div className={classes.caption}>
            You have joined <span className={classes.companyName}>{name}</span> as your company page.
          </div>
          <div className={classes.information}>Let's get you started with managing your products.</div>
          <div className={classes.buttons}>
            <Button
              data-node="get_started"
              size="middle"
              variant="contained"
              color="secondary"
              className={classes.claimButton}
              type="submit"
              onClick={this.redirectToCompany}
            >
              Get Started!
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Congratulations);
