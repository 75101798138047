import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.api_v2_companies_path(params);
    return FetchHelpers.get(url);
  },

  create(params) {
    const url = Routes.api_v2_companies_path();
    return FetchHelpers.post(url, { company: params });
  },
};
