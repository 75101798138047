export default theme => ({
  information: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(12),
    marginBottom: 35,
    color: theme.palette.gray70,
  },
  formContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'center',
    flexGrow: 1,
    padding: '0 0 45px 0',
  },
  logoContainer: {
    width: 200,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 35px',
  },
  form: {
    width: 650,
  },
  companyName: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 'bold',
  },
  claimButton: {
    width: 200,
    marginTop: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
  },
  caption: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(26),
    marginBottom: 10,
    color: theme.palette.gray70,
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(35),
    marginBottom: 20,
    color: theme.palette.gray70,
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});
