export default theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },

  role: {
    width: 200,
    margin: `0 ${theme.spacing(2)}`,
  },

  textFields: {
    width: 300,
    margin: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& label, p': {
      color: theme.palette.text.primary,
    },
  },

  password: {
    margin: 0,
    '&>div': {
      marginTop: 0,
      marginBottom: 15,
    },
  },

  actions: {
    width: 200,
    marginTop: 60,
    marginBottom: theme.spacing(3),
  },

  errorMessage: {
    color: theme.palette.required,
  },
});
